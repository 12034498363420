.footer {
	
}

.footer-body {
	padding-top: 1.875rem;
	padding-bottom:2vh;
}

.footer-menu a {
	color:#fff;
	text-decoration: none;
	font-weight: 600;
}

.footer-menu a:hover {
	color:#7E7272;
	color:rgba(255, 255, 255, 0.4);
}

.footer-menu ul {
	padding:0;
	margin:0;
}

.footer-menu li{
	list-style: none;
	padding-bottom: 0.5rem;
}


.footer-copy a {
	color:#fff;
	text-decoration: none;
	font-size: .9rem;
}

.footer-copy a:hover {
	color:#7E7272;
	color:rgba(255, 255, 255, 0.4);
}
