.jumbotron {
	background-color: transparent;
	//background: url("../images/hero.jpg") no-repeat center;
	//background-size: cover;
	padding-top: 15%;
	padding-bottom: 15%;
	min-height: 50vh;

}




@media (min-width: 800px) {
	.jumbotron {
		min-height: 45vh;

	}
}

@media (min-width: 1024px) {
	.jumbotron {
		min-height: 36vh;

	}
}