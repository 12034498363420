//body {
//	padding-top: 70px;
//}
.img-thumbnail {
	max-height: 180px;
}

audio {
	width: 100%;
}

