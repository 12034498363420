body {

	color: $secondary;
}

a {
	color: $secondary;
	font-weight: 600;
}


a.btn.btn-case {
	text-decoration: none;
	background-color: $secondary;
	color: #fff;
}

a.btn.btn-case:hover {
	background-color: $secondary-hover;
	color: #fff;
}

a.btn.btn-case:focus {
	outline: none;
}

.alert {
	border-radius: 0.5rem;
	border: solid 1px $secondary;
	background-color: #efefef
}

//a[href^="mailto:"]::before {
//  content: "\f003";
//  padding-right: 5px;
//  font-family: FontAwesome;
//}

a[href^="mailto:"] {
	text-decoration: underline;
}


div.resources a {
	display: block;
	padding-bottom: 1em;
}
/*classes utilitaires*/
.f-16 {
	line-height: 28px;
}

.f-60 {
	font-size: 50px;
	line-height: 60px;
}

.f-22 {
	font-size: 22px;
	line-height: 32px;
}

.pt-60 {
	padding-top: 60px;
}

.pb-45 {
	padding-bottom: 45px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mt-20 {
	margin-top: 20px;
}

.mb-25 {
	margin-bottom: 25px;
}
