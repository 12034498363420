  /* Set the border color */ 
//.navbar-light .navbar-toggler-icon {
//    background-image: url(/assets/images/burger.svg);
//
.navbar-light .navbar-toggler-icon {
    background-image: url();
}

.navbar-container {
	min-height: 70.967px;
	-webkit-box-shadow: 0 5px 6px -7px black;
	   -moz-box-shadow: 0 5px 6px -7px black;
	        box-shadow: 0 5px 6px -7px black;
}

.bg-light {
	background-color: #fff !important;
}
.navbar .navbar-nav .nav-link {
	text-decoration: none;
	font-weight: bold;
	color: $secondary;
	padding-top: 5px;
	//color: rgba(94, 79, 79, 0.80);
}
.navbar .navbar-nav .nav-link:hover {
	color: $secondary;
	color: $secondary-hover;
}

.navbar .navbar-nav .nav-link:not(.disabled):hover,
.navbar .navbar-nav .nav-link:not(.disabled):focus {
  color: $secondary;
  color: $secondary-hover;
}

.navbar .navbar-nav .nav-item.active .nav-link {
	 color: $primary;
}

.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link:focus,
.navbar .navbar-nav .nav-item.show .nav-link,
.navbar .navbar-nav .nav-item.show .nav-link:hover,
.navbar .navbar-nav .nav-item.show .nav-link:focus {
  color: #fff;
  background-color: $secondary;
  background-color: $secondary-hover;
}

svg.icone{
	color:$secondary;
	width: 1.5rem;
}
svg.icone:hover{
	color:lighten($secondary, 15%);
}

.navbar .navbar-nav .nav-link {
  border-radius: .25rem;
}

@media (max-width: 1199px) {
	.navbar .navbar-nav .nav-link {
		padding-left: 10px;
	}
	
}

