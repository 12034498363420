.card-header button.btn:focus {
	box-shadow: none;
}

.card-header {
	background-color: #efefef;
}

.card-header h2 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.card-header i {
	align-self: center;
	font-size: 1rem;
	color: $secondary;
}

.card-header button {
	color: inherit;
}

.card-header:hover {
	cursor: pointer;
}

.card-body p {
	margin-left: 1rem;
}

.card-header:nth-child(1) {
	border-bottom: 0;
}

.accordion > .card .card-header:nth-child(1) {
	margin-bottom: 0;
}

.card-bg-color {
	background-color: #efefef;
}

//remove margin for small devices
@media (max-width: 991px) {
	.card-body p {
		margin-left: 0;
	}
}

